@font-face {
  font-family: TruenoLight;
  src: url("../assets/fonts/truenolt.otf");
}
@font-face {
  font-family: TruenoReg;
  src: url("../assets/fonts/truenorg.otf");
}
@font-face {
  font-family: TruenoSemiB;
  src: url("../assets/fonts/truenosbd.otf");
}
@font-face {
  font-family: TruenoBold;
  src: url("../assets/fonts/truenobd.otf");
}
@font-face {
  font-family: TruenoBlack;
  src: url("../assets/fonts/truenoblk.otf");
}

.stars-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
}
.stars-outer::before {
  content: "\2605   \2605   \2605   \2605   \2605";
  color: #999999;
}
.stars-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.stars-inner::before {
  content: "\2605   \2605   \2605   \2605   \2605";
  color: #ffe500;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #f1f2f6;
  height: 50px;
  width: 100%;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
}

.react-datepicker__input-container input::placeholder {
  color: black;
}
